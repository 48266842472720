/* eslint-disable @typescript-eslint/no-empty-function */
import {
  convertDecimal,
  dateToDateString,
  ellipsisString,
  isMigrateFromElexys,
  convertDecimalAfterComma,
  firstCapitalize,
  splitNumber
} from "@/app/infrastructures/misc/Utils";
import { DetailCustomProcess } from "@/domain/entities/CustomProcess";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { AccountController } from "@/app/ui/controllers/AccountController";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my";
import { createI18n } from "vue-i18n";

let doc: any;
let translate: any = () => {};

const addIndexElexys = () => {
  return isMigrateFromElexys() ? 1 : 0;
};

class InternalPrintData {
  name = "";
  createdBy = "";
  type = "";
  constructor(fields?: Partial<InternalPrintData>) {
    Object.assign(this, fields);
  }
}

const internalData = (params: {
  type?: string;
  doc?: any;
  dataHead?: DetailCustomProcess;
}) => {
  if (params.type?.match(/internal|^customer service$/i)) {
    return new InternalPrintData({
      name: translate("Nama User"),
      type: translate("Tipe User"),
      createdBy: params.dataHead?.customProcessCreatedBy
    });
  }
  return new InternalPrintData({
    name: translate("Nama Partner"),
    type: translate("Tipe Partner"),
    createdBy: params.dataHead?.customProcessPartnerName
  });
};

const drawCellCondition = (cell: any): Array<boolean> => [
  cell.column.dataKey === "customStatusGrossWeight" &&
    (cell.row.section === "head" || cell.row.section === "body"),
  cell.column.dataKey === "customStatusVolumeWeight" &&
    (cell.row.section === "head" || cell.row.section === "body"),
  cell.column.dataKey === "customStatuschargeableWeightWeight" &&
    (cell.row.section === "head" || cell.row.section === "body"),
  cell.column.dataKey === "customStatuschargeableWeightWeight" &&
    (cell.row.section === "head" || cell.row.section === "body"),
  cell.column.dataKey === "customStatusOriginId" && cell.row.section === "body",
  cell.column.dataKey === "customStatusDestinationId" &&
    cell.row.section === "body"
];

const isReverseJourney = (dataHead: DetailCustomProcess) =>
  !!AccountController.accountData.isRtsFeatureAvailable &&
  !!dataHead.customProcessLatestStatus.match(/RTS|^REROUTE/gi);

let lastPage = 0;
let lastY = 0;
let lastHeight = 0;

const drawCellData = (cell: any) => {
  if (drawCellCondition(cell)[0]) {
    cell.row.cells[5 + addIndexElexys()].styles = {
      ...cell.row.cells[5 + addIndexElexys()].styles,
      halign: "center"
    };
  }
  if (drawCellCondition(cell)[1]) {
    cell.row.cells[6 + addIndexElexys()].styles = {
      ...cell.row.cells[6 + addIndexElexys()].styles,
      halign: "center"
    };
  }
  if (drawCellCondition(cell)[2]) {
    cell.row.cells[7 + addIndexElexys()].styles = {
      ...cell.row.cells[7 + addIndexElexys()].styles,
      halign: "center"
    };
  }
  if (drawCellCondition(cell)[3]) {
    cell.row.cells["customStatuschargeableWeightWeight"].styles = {
      ...cell.row.cells["customStatuschargeableWeightWeight"].styles,
      halign: "center"
    };
  }
  if (drawCellCondition(cell)[4]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells["customStatusOriginId"].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells["customStatusOriginId"].x + 0.6, //x
          cell.row.cells["customStatusOriginId"].y + //y
            cell.row.cells["customStatusOriginId"].contentHeight /
              (1.6333 +
                1.75 *
                  cell.row.cells["customStatusDestinationCity"].text.filter(
                    (item: any) => item !== ""
                  ).length) +
            (0.0083 + 5.675 * index),
          cell.row.cells["customStatusOriginId"].contentWidth - 1,
          3,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }

  if (drawCellCondition(cell)[5]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells["customStatusDestinationId"].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells["customStatusDestinationId"].x + 0.6, //x
          cell.row.cells["customStatusDestinationId"].y + //y
            cell.row.cells["customStatusDestinationId"].contentHeight /
              (1.6333 +
                1.75 *
                  cell.row.cells["customStatusOriginId"].text.filter(
                    (item: any) => item !== ""
                  ).length) +
            (0.0083 + 5.675 * index),
          cell.row.cells["customStatusDestinationId"].contentWidth - 1,
          3,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
};
const generateManifest = async (
  dataHead: DetailCustomProcess,
  data: any[],
  logo: string,
  typeUser: string
) => {
  doc = new jsPDF("p", "mm", "a4", true);
  const pdfWidth = doc.internal.pageSize.width;
  const logoProperties = doc.getImageProperties(logo);
  const logoAspectRatio = logoProperties.height / logoProperties.width;

  const imageWidth = 40;
  const imageHeight = logoAspectRatio * 40;
  doc.addImage(
    logo,
    "PNG",
    pdfWidth - imageWidth - 9,
    11,
    imageWidth,
    imageHeight,
    "logo",
    "FAST",
    0
  );
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.setTextColor("#4d4d4d");
  doc.text("Custom Process Manifest", 10, 18);
  doc.setFillColor("#e0e0e0");
  doc.rect(10, 25, 190, 0.2, "F");
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);

  // left column
  doc.setTextColor("#4d4d4d");
  doc.setFillColor("#ebecf0");

  doc.text(`${internalData({ type: typeUser }).name}`, 10, 35);
  doc.text(
    `: ${ellipsisString(
      internalData({ type: typeUser, dataHead }).createdBy,
      65
    )}`.toUpperCase(),
    38,
    35
  );

  doc.text(`${internalData({ type: typeUser }).type}`, 10, 42);
  doc.text(`: ${typeUser}`, 38, 42);

  doc.text(translate("Dibuat Oleh"), 10, 49);
  doc.text(`: ${firstCapitalize(dataHead.customProcessCreatedBy)}`, 38, 49);

  doc.text(translate("Tanggal Dibuat"), 10, 56);
  doc.text(
    `: ${dateToDateString(
      new Date(dataHead.customProcessCreatedAt),
      false,
      false,
      AccountController.accountData.account_type_detail.countryCode.toLowerCase()
    )}`,
    38,
    56
  );

  doc.text(translate("Status Terbaru"), 10, 63);
  doc.text(`: ${dataHead.customProcessLatestStatus}`, 38, 63);

  // right column
  doc.setTextColor("#4d4d4d");
  doc.setFillColor("#ebecf0");

  doc.text(translate("Total STT"), 110, 35);
  doc.text(`: ${dataHead.customProcessTotalStt}`, 145, 35);

  doc.text(translate("Total Koli"), 110, 42);
  doc.text(`: ${dataHead.customProcessTotalPiece}`, 145, 42);

  doc.text(translate("Total Berat Kotor"), 110, 49);
  doc.text(
    `: ${splitNumber(dataHead.customProcessTotalGrossWeight)} Kg`,
    145,
    49
  );

  doc.text(translate("Total Berat Dimensi"), 110, 56);
  doc.text(
    `: ${splitNumber(dataHead.customProcessTotalVolumeWeight)} Kg`,
    145,
    56
  );

  doc.text(translate("Total Berat Dikenakan Biaya"), 110, 63);
  doc.text(
    `: ${splitNumber(dataHead.customProcessTotalChargeableWeight)} Kg`,
    145,
    63
  );

  const dataLength = data.length;
  let columns: any[] = [];
  const columnsData = () => [
    {
      header: "No.",
      dataKey: "no"
    },
    {
      header: `No. STT`,
      dataKey: "customStatusSttNo"
    },
    {
      header: translate("Kota Asal"),
      dataKey: "customStatusOriginCity"
    },
    {
      header: translate("Kota Tujuan"),
      dataKey: "customStatusDestinationCity"
    },
    {
      header: "Origin",
      dataKey: "customStatusOriginId"
    },
    {
      header: translate("Dest."),
      dataKey: "customStatusDestinationId"
    },
    {
      header: translate("Berat\nKotor"),
      dataKey: "customStatusGrossWeight"
    },
    {
      header: translate("Berat\nDimensi"),
      dataKey: "customStatusVolumeWeight"
    },
    {
      header: translate("Berat\nDikenakan Biaya"),
      dataKey: "customStatuschargeableWeightWeight"
    }
  ];
  const columnStyles = [
    [
      { cellWidth: 10 },
      { cellWidth: 30 },
      { cellWidth: 30 },
      { cellWidth: 20 },
      { cellWidth: 20 },
      { cellWidth: 13 },
      { cellWidth: 13 },
      { cellWidth: 12 },
      { cellWidth: 12 },
      { cellWidth: 30 }
    ]
  ];
  columns = columnsData();
  if (isReverseJourney(dataHead)) {
    columns.splice(2, 0, {
      header: translate("No. STT Pengganti"),
      dataKey: "customStatusSttReplacement"
    });
  } else {
    columnStyles.splice(2, 1);
  }
  doc.autoTable({
    body: data,
    columns: columns,
    theme: "plain",
    startY: doc.internal.getCurrentPageInfo().pageNumber === 1 ? 70 : 0,
    margin: { top: 7, left: 10, right: 10, bottom: 7 },
    headStyles: {
      font: "helvetica",
      fontStyle: "bold",
      fillColor: "#f5f6f7",
      textColor: "#1a1421",
      fontSize: 6,
      halign: "left",
      valign: "top",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 }
    },
    bodyStyles: {
      font: "helvetica",
      fontStyle: "normal",
      fillColor: "#fff",
      textColor: "#1a1421",
      fontSize: 6,
      halign: "left",
      valign: "top",
      lineColor: "#e0e0e0",
      lineWidth: { top: 0.1, right: 0, bottom: 0.1, left: 0 },
      cellPadding: { top: 3, right: 2, bottom: 3, left: 2 },
      cellWidth: "auto"
    },
    columnStyles: columnStyles,
    willDrawCell: (cell: any) => {
      drawCellData(cell);

      lastPage = cell.pageNumber;
      lastY = cell.cursor.y;
      lastHeight = cell.row.height;
      if (cell.row.index === dataLength - 2 && lastY + lastHeight + 20 > 270) {
        cell.cell.contentHeight = 100;
        cell.row.height = 100;
      }
    }
  });
  lastY = lastY + lastHeight + 20;
  lastY = lastY > 270 ? 270 : lastY;
  doc.setPage(lastPage);
  doc.setFontSize(8);
  doc.text(translate("Station Admin"), 19 + 90, lastY);
  doc.text("(.................................)", 14 + 90, lastY + 20);
  doc.text(translate("Kurir"), 60 + 90, lastY);
  doc.text("(.................................)", 50 + 90, lastY + 20);
  doc.text(translate("Operations Supervisor"), 84 + 90, lastY);
  doc.text("(.................................)", 84 + 90, lastY + 20);

  doc.setProperties({
    title: "Custom Process Manifest"
  });

  doc.autoPrint();

  window.open(doc.output("bloburl"), "_blank");
};

const ManifestPdfV2 = (
  printData: DetailCustomProcess,
  logo: any,
  typeUser: string
) => {
  const i18nInstance = createI18n({
    legacy: false,
    locale: AccountController.accountData.account_type_detail.countryCode.toLowerCase(),
    fallbackLocale: "id",
    globalInjection: false,
    messages: {
      en,
      id,
      my
    }
  });
  translate = i18nInstance.global.t;

  const data: Array<any> = [];
  let no = 1;
  for (const e of printData.stt) {
    data.push({
      no: no,
      customStatusSttNo: e.sttNo,
      customStatusSttReplacement: e.sttReplacementNo || e.remarks,
      customStatusOriginCity: e.originCityName,
      customStatusDestinationCity: e.destinationCityName,
      customStatusOriginId: e.originCityId,
      customStatusDestinationId: e.destinationCityId,
      customStatusGrossWeight: `${convertDecimal(
        Number(convertDecimalAfterComma(e.totalGrossWeight, 2))
      )} Kg`,
      customStatusVolumeWeight: `${convertDecimal(
        Number(convertDecimalAfterComma(e.totalVolumeWeight, 2))
      )} Kg`,
      customStatuschargeableWeightWeight: `${convertDecimal(
        Number(convertDecimalAfterComma(e.chargeableWeight, 2))
      )} Kg`
    });
    no++;
  }

  generateManifest(printData, data, logo, typeUser);
};

export default ManifestPdfV2;
